import SpaceFi from "../images/SpaceFi 1.png"
import Tel from "../images/Telegram 1.png"
import X from "../images/X 2.png"
import "../style/TopBar.css"
const TopBar = () => {
    return (
        <header>
            <div className="Header">
                <div>
                    <img src={SpaceFi} alt="SpaceFi" />
                </div>
                <div >
                    <span>
                        SPACEFI
                    </span>
                    <span>
                        Project
                    </span>
                </div>
            </div>
            <div className="JoinUs">

                <div>
                    <span>Join US</span>
                </div>
                <div>
                    <a href="https://x.com/Spacefi_Project" target="_blank" rel="noopener noreferrer">
                        <img src={X} alt="PlayNow" />
                    </a>
                    <a href="https://t.me/spacefi_bot" target="_blank" rel="noopener noreferrer">
                        <img src={Tel} alt="PlayNow" />
                    </a>
                </div>
            </div>

        </header>
    )
}
export default TopBar;