import "../style/text.css"
const Text = () => {
    return (
        <div className="TextHolder">
            <p className="Text">
                Discover whole new space
                through mining
                developing and playing
            </p>
        </div>
    );
}
export default Text;