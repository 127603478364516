import Throne from "../images/Sitting on Throne 1.png"
import PlayNowImg from "../images/PLaynow 2 1.png"
import "../style/PlayNow.css"
const PlayNow = () => {
    return (
        <div className="PlayNowHolder">
            <img src={Throne} alt="SpaceFi" className="ImageThrone" />
            <a href="https://t.me/spacefi_bot" target="_blank" rel="noopener noreferrer">
                <img src={PlayNowImg} alt="PlayNow" className="ImageBG" />
                <span>PLAY NOW</span>
            </a>
        </div>
    );
}
export default PlayNow;