import ig from "../images/Insta 1.png"
import tel from "../images/Telegram 2.png"
import x from "../images/X 3.png"
import yt from "../images/Youtube 1.png"
import "../style/Footer.css"
const Footer = () => {
    return (
        <div>
            <div className ="links">
                <a href="https://t.me/spacefi_bot" target="_blank" rel="noopener noreferrer">
                    <img src={tel} alt="PlayNow" />
                </a>
                <a href="https://x.com/Spacefi_Project" target="_blank" rel="noopener noreferrer">
                    <img src={x} alt="PlayNow" />
                </a>
                <a href="https://www.youtube.com/@SpacefiProject" target="_blank" rel="noopener noreferrer">
                    <img src={yt} alt="PlayNow" />
                </a>
                <a href="https://www.instagram.com/spaceficoin" target="_blank" rel="noopener noreferrer">
                    <img src={ig} alt="PlayNow" />
                </a>
            </div>
            <div className = "rightReserv">
                <span>2024 Spacefi.me | All rights reserved </span>
            </div>
        </div>
    );
}
export default Footer;