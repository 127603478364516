import "../style/Plans.css"
const Plans = () => {
    return (
        <div className="PlansContainer">
            <h1>
                Upcoming Plans
            </h1>
            <div className="Plans">
                <div className="Plan">
                    <div className="chap green">
                        <span>Sep</span>
                        <span>2024</span>
                    </div>
                    <p className="rast Sep">
                        <span>
                            Launch basic version of game
                        </span>
                        <span>
                            mining feature
                        </span>
                    </p>
                </div>
                <div className="Plan plan2">
                    <div className="chap">
                        <span>Oct</span>
                        <span>2024</span>
                    </div>
                    <p className="rast">
                        TBD
                    </p>
                </div>
                <div className="Plan plan3">
                    <div className="chap">
                        <span>Nov</span>
                        <span>2024</span>
                    </div>
                    <p className="rast">
                        TBD
                    </p>
                </div>
                <div className="Plan plan4">
                    <div className="chap">
                        <span>Dec</span>
                        <span>2024</span>
                    </div>
                    <p className="rast">
                        TBD
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Plans;