import './App.css';
import Footer from './Components/Footer';
import Plans from './Components/Plans';
import PlayNow from './Components/PlayNow';
import Text from './Components/Text';
import TopBar from './Components/TopBar';

function App() {
  return (
    <div className="App">
        <TopBar />
        <Text />
        <PlayNow />
        <Plans />
        <Footer />
    </div>
  );
}

export default App;
